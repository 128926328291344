import { useContext, useEffect, useState } from "react";
import { DataContext } from "../context/Data";
import Label from "./Label";
import Preview from "./Preview";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";

function Invoices({ filtered }) {
  const { profile, invoices } = useContext(DataContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (filtered === 0) {
      setItems(invoices);
    } else {
      const copy = [...invoices];
      const res = copy.filter((e) => e.state === filtered);
      setItems(res);
    }
  }, [filtered, invoices]);

  return (
    <>
      <Table size="md" fontSize="sm">
        <Thead>
          <Tr>
            <Th>Fecha</Th>
            <Th>Folio</Th>
            <Th>Proyecto</Th>
            <Th>Status</Th>
            <Th>Previtsa</Th>
            <Th>Comentarios</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items.map((item, i) => (
            <Tr key={i}>
              <Td>{item.date}</Td>
              <Td>{item.folio}</Td>
              <Td>{item.proyect}</Td>
              <Td>
                <Label label={item.state} />
              </Td>
              <Td>
                <Preview
                  path={`invoices/${profile.uid}/${item.time}/${item.pdf}`}
                />
              </Td>
              <Td>{item.message ? item.message : "--"}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
}

export default Invoices;
