import { labelName } from "../util/labels";
import { Tag } from "@chakra-ui/react";

function Label({ label }) {
  if (label === 1) {
    return (
      <Tag size="sm" colorScheme="yellow">
        {labelName[1]}
      </Tag>
    );
  }

  if (label === 2) {
    return (
      <Tag size="sm" colorScheme="red">
        {labelName[2]}
      </Tag>
    );
  }

  if (label === 3) {
    return (
      <Tag size="sm" colorScheme="green">
        {labelName[3]}
      </Tag>
    );
  }

  return (
    <Tag size="sm" colorScheme="red">
      {labelName[0]}
    </Tag>
  );
}

export default Label;
