import { useHistory } from "react-router-dom";
import { Box, Button, Text, Heading } from "@chakra-ui/react";

function Incomplete() {
  const history = useHistory()

  function handleClick() {
    history.push("/documentos");
  }

  return (
    <>
      <Box>
        <Heading fontSize="bg">Documentación Incompleta o En revisión</Heading>
        <Text fontSize="md" mt={2}>
          Para comenzar a enviar sus facturas por favor suba los documentos
          requeridos y/o espere a que sean aprobados.
        </Text>
        <Button
          mt={3}
          rounded={4}
          colorScheme="blue"
          size="md"
          onClick={handleClick}
        >
          Ver Documentación
        </Button>
      </Box>
    </>
  );
}

export default Incomplete;
