import { useState, useEffect, useContext } from "react";
import { DataContext } from "./context/Data";
import Invoices from "./particles/Invoices";
import Nav from "./particles/Nav";
import AddInvoice from "./particles/AddInvoice";
import Incomplete from "./Incomplete";
import User from "./particles/User";

import {
  Flex,
  Grid,
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import { AddIcon } from "@chakra-ui/icons";

function Dashboard() {
  const { profile } = useContext(DataContext);
  const [filtered, setFiltered] = useState(0);
  const [complete, setInComplete] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    let count =
      profile.file1 +
      profile.file2 +
      profile.file3 +
      profile.file4 +
      profile.file5 +
      profile.file6;
    if (count === 18) {
      setInComplete(true);
    } else {
      setInComplete(false);
    }
  }, [profile]);

  return (
    <>
      <Nav title={"Facturas"} />
      <Box background="gray.100" minHeight="100vh" py={24} px={2}>
        <Grid justifyContent="center" templateColumns="minmax(auto,960px)">
          <User />

          <Flex
            background="white"
            pt={{ base: 3, sm: 10 }}
            px={{ base: 3, sm: 10 }}
            boxShadow="sm"
            overflowX="auto"
            flexDirection={{ base: "column-reverse", md: "row" }}
            justifyContent="space-between"
            roundedTopLeft={4}
            roundedTopRight={4}
          >
            <ButtonGroup
              size="sm"
              variant="outline"
              isAttached
              mt={{ base: 3, md: 0 }}
            >
              <Button
                roundedTopLeft={4}
                roundedBottomLeft={4}
                onClick={() => setFiltered(1)}
              >
                En Revisión
              </Button>
              <Button onClick={() => setFiltered(2)}>Rechazadas</Button>
              <Button onClick={() => setFiltered(3)}>Aprobadas</Button>
              <Button
                roundedTopRight={4}
                roundedBottomRight={4}
                onClick={() => setFiltered(0)}
              >
                Todas
              </Button>
            </ButtonGroup>
            <Button
              onClick={onOpen}
              colorScheme="blue"
              variant="solid"
              size="sm"
              rounded={4}
              leftIcon={<AddIcon />}
            >
              Subir Factura
            </Button>
          </Flex>

          <Flex
            direction="column"
            background="white"
            py={{ base: 6, sm: 10 }}
            px={{ base: 3, sm: 10 }}
            boxShadow="sm"
            overflowX="auto"
            roundedBottomLeft={4}
            roundedBottomRight={4}
          >
            <Invoices filtered={filtered} />
          </Flex>
        </Grid>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay onClick={() => null} />
        <ModalContent pb={6}>
          <ModalHeader>Subir Factura</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{complete ? <AddInvoice /> : <Incomplete />}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Dashboard;
