import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./context/Auth";
import { auth, db } from "./util/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getDoc, doc } from "firebase/firestore";

import {
  Flex,
  Box,
  Image,
  Heading,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Link,
} from "@chakra-ui/react";

function Login({ history }) {
  const { currentUser } = useContext(AuthContext);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setAlertMessage(null);
    setAlert(false);
    const { email, password } = event.target.elements;
    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
    } catch (error) {
      signOut(auth);
      setLoading(false);
      setAlertMessage("Crendenciales incorrectas");
      setAlert(true);
      console.log(error);
    }
  }

  useEffect(() => {
    const verifyState = async (uid) => {
      const docRef = doc(db, "providers", uid);
      const snap = await getDoc(docRef);
      if (snap.exists()) {
        let profile = snap.data();
        if (profile.userState) {
          history.push("./");
          return;
        }
        signOut(auth);
        setAlertMessage("Usuario Desactivado");
        setAlert(true);
        setLoading(false);
      }
      signOut(auth);
      setAlertMessage("Usuario Desactivado");
      setAlert(true);
      setLoading(false);
    };

    if (currentUser) {
      verifyState(currentUser.uid);
    }
  }, [currentUser, history]);

  return (
    <Flex
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
      background="gray.100"
      py={6}
      px={2}
      flexDirection="column"
    >
      <Box w="180px" mb={6}>
        <Image src="bengala.svg" alt="Casa Bengala" w="100%" />
      </Box>
      <Flex
        direction="column"
        background="white"
        p={10}
        rounded={4}
        boxShadow="lg"
        w="380px"
      >
        <Heading mb={4} fontSize="2xl">
          Proveedores
        </Heading>
        <form onSubmit={handleSubmit}>
          <Flex direction="column">
            <FormLabel fontSize="sm">Email:</FormLabel>
            <Input
              placeholder="Ingresa tu correo"
              type="email"
              name="email"
              size="md"
              mb={3}
              rounded={4}
              maxLength={70}
            />
            <FormLabel fontSize="sm">Password:</FormLabel>
            <Input
              type="password"
              placeholder="********"
              name="password"
              size="md"
              mb={3}
              rounded={4}
              maxLength={16}
            />
            <Button
              type="submit"
              colorScheme="blue"
              loadingText="Verificando"
              isLoading={loading}
              rounded={4}
            >
              Iniciar Sesión
            </Button>
            {alert ? (
              <Alert status="error" mt={3}>
                <AlertIcon />
                <AlertTitle mr={2}>Error:</AlertTitle>
                <AlertDescription>{alertMessage}</AlertDescription>
              </Alert>
            ) : null}
          </Flex>
        </form>
      </Flex>
      <Link href="https://casabengala.com/" isExternal mt={6} fontSize="sm" color="gray.500">
        www.casabengala.mx
      </Link>
    </Flex>
  );
}

export default Login;
