import { useContext, useState, useRef } from "react";
import { AuthContext } from "../context/Auth";
import { db, storage } from "../util/firebase";
import { addDoc, collection } from "firebase/firestore";
import { ref, uploadBytes } from "firebase/storage";
import { QuestionIcon } from "@chakra-ui/icons";

import {
  Flex,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Tooltip,
} from "@chakra-ui/react";

function AddInvoice() {
  const { currentUser } = useContext(AuthContext);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pdfName, setPdfName] = useState("Seleccionar PDF");
  const [xmlName, setXmlName] = useState("Seleccionar XML");

  /* ======== FILES HANDLE ======== */

  const inputPdfRef = useRef(null);
  const inputXmlRef = useRef(null);

  function handlePDFClick(e) {
    e.preventDefault();
    inputPdfRef.current.click();
  }

  function handlePDFChange(e) {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      setAlertMessage(null);
      setAlert(false);
      setPdfName(file.name);
      return;
    }
    setAlertMessage("El formato debe ser PDF");
    setAlert(true);
  }

  function handleXMLClick(e) {
    e.preventDefault();
    inputXmlRef.current.click();
  }

  function handleXMLChange(e) {
    const file = e.target.files[0];
    if (file.type === "text/xml") {
      setAlertMessage(null);
      setAlert(false);
      setXmlName(file.name);
      return;
    }
    setAlertMessage("El formato debe ser XML");
    setAlert(true);
  }

  /* ======== /FILES HANDLE ======== */

  function saveInvoiceData(data) {
    return addDoc(collection(db, "invoices"), data);
  }

  function uploadInvoiceFiles(path, file) {
    const storageRef = ref(storage, `invoices/${path}/${file.name}`);
    return uploadBytes(storageRef, file);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setAlertMessage(null);
    setAlert(false);
    setLoading(true);
    setSuccess(false);

    const { folio, pdf, xml, proyect } = e.target.elements;

    const invoice = {
      folio: folio.value,
      pdf: pdf.files[0],
      xml: xml.files[0],
    };

    if (folio.value.length === 0) {
      setAlertMessage("Debe agregar un Folio");
      setAlert(true);
      setLoading(false);
      return;
    }

    if (proyect.value.length === 0) {
      setAlertMessage("Debe agregar un proyecto");
      setAlert(true);
      setLoading(false);
      return;
    }

    if (invoice.pdf === undefined) {
      setAlertMessage("Agregue su PDF");
      setAlert(true);
      setLoading(false);
      return;
    }

    if (invoice.pdf.type !== "application/pdf") {
      setAlertMessage("El formato debe ser PDF");
      setAlert(true);
      setLoading(false);
      return;
    }

    if (invoice.xml === undefined) {
      setAlertMessage("Agregue su XML");
      setAlert(true);
      setLoading(false);
      return;
    }

    if (invoice.xml.type !== "text/xml") {
      setAlertMessage("El formato debe ser XML");
      setAlert(true);
      setLoading(false);
      return;
    }

    const time = new Date().getTime();
    const path = `${currentUser.uid}/${time}`;

    const data = {
      folio: folio.value,
      proyect: proyect.value,
      path: path,
      provider: currentUser.uid,
      pdf: invoice.pdf.name,
      xml: invoice.xml.name,
      time: time,
      state: 1,
    };

    try {
      await uploadInvoiceFiles(path, invoice.pdf);
      await uploadInvoiceFiles(path, invoice.xml);
      await saveInvoiceData(data);
      setLoading(false);
      setSuccess(true);
      setPdfName("Seleccionar PDF");
      setXmlName("Seleccionar XML");
      inputXmlRef.current.value = "";
      inputPdfRef.current.value = "";
      folio.value = "";
      proyect.value = "";
    } catch (error) {
      setAlertMessage(error.message);
      setAlert(true);
      setLoading(false);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Flex flexDirection="column">
          <FormLabel>Folio</FormLabel>
          <Input
            name="folio"
            size="md"
            mb={3}
            rounded={4}
            maxLength={16}
            type="text"
          />
          <FormLabel>
            Proyecto{" "}
            <Tooltip
              placement="top"
              hasArrow
              label="ID asignado por Casa Bengala"
              fontSize="xs"
            >
              <QuestionIcon w={4} h={4} color="blue.500" />
            </Tooltip>
          </FormLabel>
          <Input
            name="proyect"
            size="md"
            mb={3}
            rounded={4}
            maxLength={16}
            type="text"
          />
          <FormLabel>PDF:</FormLabel>
          <Button
            type="submit"
            rounded={4}
            size="md"
            variant="outline"
            onClick={handlePDFClick}
            mb={3}
          >
            {pdfName}
          </Button>
          <Input
            type="file"
            display="none"
            ref={inputPdfRef}
            name="pdf"
            onChange={handlePDFChange}
          />
          <FormLabel>XML:</FormLabel>
          <Button
            type="submit"
            rounded={4}
            size="md"
            variant="outline"
            onClick={handleXMLClick}
            mb={3}
          >
            {xmlName}
          </Button>
          <Input
            type="file"
            display="none"
            ref={inputXmlRef}
            name="xml"
            onChange={handleXMLChange}
          />
          <Button
            type="submit"
            colorScheme="blue"
            size="md"
            mb={3}
            loadingText="Subiendo Archivos"
            isLoading={loading}
            rounded={4}
          >
            Subir Factura
          </Button>
          {alert ? (
            <Alert status="error" mb={3} rounded={4}>
              <AlertIcon />
              <AlertTitle mr={2}>Error:</AlertTitle>
              <AlertDescription>{alertMessage}</AlertDescription>
            </Alert>
          ) : null}
          {success ? (
            <Alert status="success" mb={3} rounded={4}>
              <AlertIcon />
              <AlertDescription>
                Factura recibida correctamente
              </AlertDescription>
            </Alert>
          ) : null}
        </Flex>
      </form>
    </>
  );
}

export default AddInvoice;
