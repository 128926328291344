import { BrowserRouter as Router, Route } from "react-router-dom";
import { DataProvider } from "./context/Data";
import { ChakraProvider } from "@chakra-ui/react";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "./Dashboard";
import Login from "./Login";
import Docs from "./Docs";

function App() {
  return (
    <>
      <ChakraProvider>
        <Router>
          <Route exact path="/login" component={Login} />
          <DataProvider>
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute exact path="/documentos" component={Docs} />
          </DataProvider>
        </Router>
      </ChakraProvider>
    </>
  );
}

export default App;
