import { useContext } from "react";
import { DataContext } from "../context/Data";
import { Flex, Tag, Text, Box, Image } from "@chakra-ui/react";

function User() {
  const { profile } = useContext(DataContext);
  return (
    <>
      <Flex mb={4} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text fontSize="sm">Usuario:</Text>
          <Tag
            colorScheme="green"
            borderRadius="full"
            pl={4}
            pr={4}
            py={2}
            ml={4}
          >
            {profile.userEmail}
          </Tag>
        </Flex>
        <Box w="120px">
          <Image src="bengala.svg" alt="Casa Bengala" w="100%" />
        </Box>
      </Flex>
    </>
  );
}

export default User;
