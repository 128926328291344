import { createContext, useEffect, useContext, useState } from "react";
import { AuthContext } from "./Auth";
import { db } from "../util/firebase";
import Loading from "../particles/Loading";

import {
  doc,
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
} from "firebase/firestore";

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const [profile, setProfile] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    if (profile.length > 0 && invoices.length > 0) {
      setPending(true);
    }
  }, [profile, invoices]);

  useEffect(() => {
    if (currentUser) {
      const unsub = onSnapshot(doc(db, "providers", currentUser.uid), (doc) => {
        let data = doc.data();
        setProfile({ uid: currentUser.uid, ...data });
      });
      return () => unsub();
    }
  }, [currentUser, setProfile]);

  useEffect(() => {
    if (currentUser) {
      const q = query(
        collection(db, "invoices"),
        where("provider", "==", currentUser.uid),
        orderBy("time", "desc")
      );
      const unsub = onSnapshot(q, (querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          const unixTime = doc.data().time;
          const date = new Date(unixTime);
          docs.push({
            ...data,
            id: data.id,
            date: date.toLocaleDateString("es-MX"),
          });
        });
        setInvoices(docs);
      });
      return () => unsub();
    }
  }, [setInvoices, currentUser]);

  if (pending) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <DataContext.Provider value={{ profile, invoices }}>
      {children}
    </DataContext.Provider>
  );
};
