export const fileData = {
  1: {
    name: "file1",
    file: "acta_constitutiva.pdf",
    title: "Acta Constitutiva",
    message: "messageFile1"
  },
  2: {
    name: "file2",
    file: "constancia_fiscal.pdf",
    title: "Constancia de situación fiscal",
    message: "messageFile2"
  },
  3: {
    name: "file3",
    file: "opinion_de_cumplimiento.pdf",
    title: "Opinión de cumplimiento",
    message: "messageFile3"
  },
  4: {
    name: "file4",
    file: "identificacion.pdf",
    title: "Identificación oficial vigente del representante legal",
    message: "messageFile4"
  },
  5: {
    name: "file5",
    file: "comprobante_de_domicilio.pdf",
    title: "Comprobante de domicilio",
    message: "messageFile5"
  },
  6: {
    name: "file6",
    file: "estado_de_cuenta.pdf",
    title: "Carátula de estado de cuenta",
    message: "messageFile6"
  },
};

export const labelName = {
  0: "Faltante",
  1: "En Revisión",
  2: "Rechazada",
  3: "Aprobada",
};
