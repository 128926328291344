import { useHistory } from "react-router-dom";
import { auth } from "../util/firebase";
import { signOut } from "firebase/auth";
import { Box, Grid, Heading, Button, Flex } from "@chakra-ui/react";

function Nav({ title }) {
  const history = useHistory();

  function SignOut(e) {
    e.preventDefault();
    signOut(auth);
  }

  function linkDocuments(e) {
    e.preventDefault();
    history.push("/documentos");
  }

  function linkHome(e) {
    e.preventDefault();
    history.push("/");
  }

  return (
    <>
      <Box
        background="white"
        height={12}
        position="fixed"
        top={0}
        left={0}
        zIndex={2}
        w="100%"
        boxShadow="lg"
      >
        <Grid
          justifyContent="center"
          templateColumns="minmax(auto,960px)"
          height={12}
          alignItems="center"
          px={2}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Heading fontSize={{ base: "lg", sm: "2xl" }}>{title}</Heading>
            <Grid templateColumns="repeat(3,auto)">
              <Button
                onClick={linkHome}
                colorScheme="blue"
                size="sm"
                variant="link"
                mr={{ base: 3, sm: 6, md: 6 }}
              >
                Inicio
              </Button>
              <Button
                onClick={linkDocuments}
                colorScheme="blue"
                size="sm"
                rounded="4"
                variant="link"
                mr={{ base: 3, sm: 6, md: 6 }}
              >
                Documentación
              </Button>
              <Button
                onClick={SignOut}
                colorScheme="blue"
                variant="solid"
                size="sm"
                rounded="4"
              >
                Salir
              </Button>
            </Grid>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export default Nav;
