import { useEffect, useState, useContext } from "react";
import { AuthContext } from "./context/Auth";

import { db } from "./util/firebase";
import { onSnapshot, doc } from "firebase/firestore";

import { fileData } from "./util/labels";
import Label from "./particles/Label";
import UploadFile from "./particles/UploadFile";
import Preview from "./particles/Preview";
import Nav from "./particles/Nav";
import User from "./particles/User";

import {
  Flex,
  Grid,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";

const count = [1, 2, 3, 4, 5, 6];

const alertInitialState = {
  visibility: false,
  status: null,
  title: null,
  message: null,
};

function Docs() {
  const { currentUser } = useContext(AuthContext);
  const [provider, setProvider] = useState([]);
  const [alert, setAlert] = useState(alertInitialState);

  function closeAlert() {
    setAlert(alertInitialState);
  }

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "providers", currentUser.uid),
      (doc) => {
        let data = doc.data();
        setProvider(data);
      }
    );
    return () => unsubscribe();
  }, [currentUser]);

  return (
    <>
      <Nav title={"Documentación"} />
      <Box background="gray.100" minHeight="100vh" py={24} px={2}>
        <Grid justifyContent="center" templateColumns="minmax(auto,960px)">
          <User />
          <Flex
            direction="column"
            background="white"
            py={{ base: 6, sm: 10 }}
            px={{ base: 3, sm: 10 }}
            rounded={4}
            boxShadow="sm"
            overflowX="auto"
          >
            {alert.visibility ? (
              <Alert status={alert.status} mb={5} rounded={4}>
                <AlertIcon />
                <AlertTitle mr={2}>{alert.title}</AlertTitle>
                <AlertDescription>{alert.message}</AlertDescription>
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={closeAlert}
                />
              </Alert>
            ) : null}

            <Table size="md" fontSize="sm">
              <Thead>
                <Tr>
                  <Th width={280}>Documento</Th>
                  <Th>Estatus</Th>
                  <Th>Prevista</Th>
                  <Th>Subir PDF</Th>
                  <Th>Comentarios</Th>
                </Tr>
              </Thead>
              <Tbody>
                {count.map((n, i) => (
                  <Tr key={i}>
                    <Td>{fileData[n].title}</Td>
                    <Td>
                      <Label label={provider[`file${n}`]} />
                    </Td>
                    <Td>
                      {provider[`file${n}`] > 0 ? (
                        <Preview
                          path={`docs/${currentUser.uid}/${fileData[n].file}`}
                        />
                      ) : null}
                    </Td>
                    <Td>
                      <UploadFile
                        name={fileData[n].name}
                        fileName={fileData[n].file}
                        setAlert={setAlert}
                        alertInitialState={alertInitialState}
                        message={fileData[n].message}
                      />
                    </Td>
                    <Td> {provider[`messageFile${n}`]}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Grid>
      </Box>
    </>
  );
}

export default Docs;
