import { useContext, useRef, useState } from "react";
import { AuthContext } from "../context/Auth";
import { db, storage } from "../util/firebase";
import { uploadBytes, ref } from "firebase/storage";
import { updateDoc, doc } from "firebase/firestore";
import { Input, Button } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

function UploadFile({ name, fileName, setAlert,alertInitialState, message }) {
  const { currentUser } = useContext(AuthContext);
  const refInput = useRef(null);
  const [loading, setLoading] = useState(false);

  function uploadFile(file) {
    const path = `docs/${currentUser.uid}/${fileName}`;
    const storageRef = ref(storage, path);
    uploadBytes(storageRef, file);
  }

  function updateProvider() {
    const docRef = doc(db, "providers", currentUser.uid);
    const data = {};
    data[name] = 1;
    data[message] = null;
    updateDoc(docRef, data);
  }

  function handleClick() {
    refInput.current.click();
  }

  async function handleChange(e) {
    setAlert(alertInitialState)
    setLoading(true);
    const file = e.target.files[0];
    if (file.type !== "application/pdf") {
      setAlert({
        visibility: true,
        status: "error",
        title: "Error:",
        message: "El Formato debe ser PDF",
      });
      setLoading(false);
      return;
    }
    try {
      await uploadFile(file);
      await updateProvider();
      e.target.value = "";
      setTimeout(() => {
        setAlert({
          visibility: true,
          status: "success",
          title: "Éxito:",
          message: "El archivo a sido enviado correctamente",
        });
        setLoading(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <Button
        type="submit"
        colorScheme="blue"
        loadingText="Cargando PDF"
        isLoading={loading}
        rounded={4}
        onClick={handleClick}
        size="xs"
        leftIcon={<AddIcon />}
      >
        Subir PDF
      </Button>
      <Input
        type="file"
        display="none"
        onChange={handleChange}
        ref={refInput}
      />
    </>
  );
}

export default UploadFile;
